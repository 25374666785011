import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/build/repo/src/components/layout/index.jsx";
import { MathJax } from 'better-react-mathjax';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Geotechnical Engineering`}</h1>
    <h2>{`Geotechnical Engineering Books`}</h2>
    <h3>{`Books:`}</h3>
    <p><a parentName="p" {...{
        "href": "https://drive.google.com/open?id=0B5kjI2-xym-PM1E3NXJTSFRzdXM"
      }}>{`Download: Problem solving in soil mechanics by A. Aysen`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://drive.google.com/open?id=0B5kjI2-xym-PQ0RzeHJyTkJsZVU"
      }}>{`Download: Advanced soil mechanics by Braja M. Das 3rd Ed.`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://drive.google.com/open?id=0B5kjI2-xym-PaGpNYmwwU1FrbGc"
      }}>{`Download: An introduction to geotechnical engineering by Robert D. Holtz and William D. Kovacs`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://drive.google.com/open?id=0B5kjI2-xym-PY0xraDdtaG82dGs"
      }}>{`Download: Soil mechanics and foundations by Muni Budhu 3rd ed.`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://drive.google.com/open?id=0B5kjI2-xym-PRElTZmZsUHZ0cHc"
      }}>{`Download: Fundamentals of soil mechanics by Aziz Akbar`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://drive.google.com/open?id=0B5kjI2-xym-PNGhneWY2T3phMVk"
      }}>{`Download: Basic soil mechanics by R. Whitlow`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://drive.google.com/open?id=0B5kjI2-xym-PQUlJLVJrem9xZlE"
      }}>{`Download: Craig’s soil mechanics by R. F. Craig 7th ed.`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://drive.google.com/open?id=0B5kjI2-xym-Pc1laMGNzTTlQdlU"
      }}>{`Download: Smith’s elements of soil mechanics  by Ian Smith 9th ed.`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://drive.google.com/open?id=0B5kjI2-xym-Pc1Nmb18xbS1vMkk"
      }}>{`Download: Elements of soil mechanics by G. N. Smith and Ian G. N. Smith 7th ed.`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://drive.google.com/open?id=0B5kjI2-xym-PNzA0ZE9ERy0tZzQ"
      }}>{`Download: Geotechnical engineering by C. Venkatramaiah 3rd ed.`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://drive.google.com/open?id=0B5kjI2-xym-PX3pwN2hLWERTbzQ"
      }}>{`Download: Physical and geotechnical properties of soil by Joseph E. Bowles`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://drive.google.com/open?id=0B5kjI2-xym-PanBNaTZmZjRsbGM"
      }}>{`Download: Geotechnical engineering principles and practices of soil mechanics and foundation engineering by VNS Murthy`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://drive.google.com/open?id=0B5kjI2-xym-PY3RPOTM0UHV0dEk"
      }}>{`Download: Principles of geotechnical engineering by Braja M. Das 7th ed.`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://drive.google.com/open?id=1MRJo3JbxUWMpCyIXlc8Wrl33-1-PH0sa"
      }}>{`Download: Soil mechanics in engineering practice by Karl Terzaghi, Ralpp B. Peck, Gholamreza Mesri 3rd ed. `}</a></p>
    <p><a parentName="p" {...{
        "href": "https://drive.google.com/open?id=1MRJo3JbxUWMpCyIXlc8Wrl33-1-PH0sa"
      }}>{`Download: Theoretical soil mechanics by Karl Terzaghi `}</a></p>
    <p><a parentName="p" {...{
        "href": "https://drive.google.com/open?id=1XzBHvfR0Qp7wpKn0PS42OL5luyjgLcBW"
      }}>{`Download: Geotechnical engineering Principles and practices by Donald P. Coduto`}</a></p>
    <h3>{`Solution manuals:`}</h3>
    <p><a parentName="p" {...{
        "href": "https://drive.google.com/open?id=0B5kjI2-xym-PaDAxX2taQW11Wkk"
      }}>{`Download: Solution manual Principles of geotechnical engineering by Braja M. Das 8th ed.`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://drive.google.com/open?id=0B5kjI2-xym-PbW9GVy1OcU9uaW8"
      }}>{`Download: Solution manual Craig’s soil mechanics by R. F. Craig 7th ed.`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://drive.google.com/open?id=1oJqyB9JW5BJczj5zJAP_jKIjjriKEztZ"
      }}>{`Download: Solution manual Smith’s elements of soil mechanics  by Ian Smith 9th ed.`}</a></p>
    <h2>{`Geotechnical Engineering I lecture slides`}</h2>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation.com/wp-content/uploads/2020/02/1.Introduction-to-soil-mechanics-and-types-of-soils.pdf"
      }}>{`Lec1.Introduction to soil mechanics and types of soils`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation.com/wp-content/uploads/2020/02/2.Weight-volume-relationships.pdf"
      }}>{`Lec2.Weight volume relationships`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation.com/wp-content/uploads/2020/02/3.Weight-volume-relationships-continue.pdf"
      }}>{`Lec3.Weight volume relationships continue`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation.com/wp-content/uploads/2020/02/4.Weight-volume-relationshipsRelative-density.pdf"
      }}>{`Lec4.Weight volume relationships+Relative density`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation.com/wp-content/uploads/2020/02/5.Analysis-of-soil-mechanically.pdf"
      }}>{`Lec5.Analysis of soil mechanically`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation.com/wp-content/uploads/2020/02/ConsolidationProblemsheeet.pdf"
      }}>{`ConsolidationProblemsheeet`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation.com/wp-content/uploads/2020/02/Lec6HydrometerAnalysis.pdf"
      }}>{`Lec6HydrometerAnalysis`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation.com/wp-content/uploads/2020/02/Lec7AtterbergLimits.pdf"
      }}>{`Lec7AtterbergLimits`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation.com/wp-content/uploads/2020/02/Lec8USCS.pdf"
      }}>{`Lec8USCS`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation.com/wp-content/uploads/2020/02/Lec9AASHTO.pdf"
      }}>{`Lec9AASHTO`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation.com/wp-content/uploads/2020/02/Lec9ProblemSheet-SoilClassification.pdf"
      }}>{`Lec9ProblemSheet-SoilClassification`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation.com/wp-content/uploads/2020/02/Lec10SoilCompaction.pdf"
      }}>{`Lec10SoilCompaction`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation.com/wp-content/uploads/2020/02/Lec11LabCompaction.pdf"
      }}>{`Lec11LabCompaction`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation.com/wp-content/uploads/2020/02/Lec12CompactionProblems.pdf"
      }}>{`Lec12CompactionProblems`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation.com/wp-content/uploads/2020/02/Lec13FieldCompaction.pdf"
      }}>{`Lec13FieldCompaction`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation.com/wp-content/uploads/2020/02/Lec14FieldCompactionProblems.pdf"
      }}>{`Lec14FieldCompactionProblems`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation.com/wp-content/uploads/2020/02/Lec15FAASoilClassification.pdf"
      }}>{`Lec15FAASoilClassification`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation.com/wp-content/uploads/2020/02/Lec16CompactionProblems.pdf"
      }}>{`Lec16CompactionProblems`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation.com/wp-content/uploads/2020/02/Lec17Consolidation.pdf"
      }}>{`Lec17Consolidation`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation.com/wp-content/uploads/2020/02/Lec18Consolidation.pdf"
      }}>{`Lec18Consolidation`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation.com/wp-content/uploads/2020/02/Lec19Consolidation-III.pdf"
      }}>{`Lec19Consolidation-III`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation.com/wp-content/uploads/2020/02/Lec20Consolidation-IV.pdf"
      }}>{`Lec20Consolidation-IV`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation.com/wp-content/uploads/2020/02/Lec21ConsolidationProblems.pdf"
      }}>{`Lec21ConsolidationProblems`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation.com/wp-content/uploads/2020/02/Lec22ConsolidationProblems-II.pdf"
      }}>{`Lec22ConsolidationProblems-II`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation.com/wp-content/uploads/2020/02/Lec23PermeabilitySeepage.pdf"
      }}>{`Lec23PermeabilitySeepage`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation.com/wp-content/uploads/2020/02/Lec24Permeability-II.pdf"
      }}>{`Lec24Permeability-II`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation.com/wp-content/uploads/2020/02/Lec25PermeabilitythruStratifiedSoils.pdf"
      }}>{`Lec25Permeability thru Stratified Soils`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation.com/wp-content/uploads/2020/02/Lec26Fielddeterminationofpermeability.pdf"
      }}>{`Lec26Field determination of permeability`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation.com/wp-content/uploads/2020/02/SoilDeposits_Lec1_2.pdf"
      }}>{`SoilDeposits_Lec1_2`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation.com/wp-content/uploads/2020/02/SoilDeposits_Lec1_3.pdf"
      }}>{`SoilDeposits_Lec1_3`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation.com/wp-content/uploads/2020/02/SoilDeposits_Lec1_4.pdf"
      }}>{`SoilDeposits_Lec1_4`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation.com/wp-content/uploads/2020/02/SoilDeposits_Lec1_5.pdf"
      }}>{`SoilDeposits_Lec1_5`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation.com/wp-content/uploads/2020/02/USCSTables.pdf"
      }}>{`USCSTables`}</a></p>
    <h3>{`Miscellaneous notes:`}</h3>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation.com/wp-content/uploads/2020/02/Moisture-Density-Analysis.pdf"
      }}>{`Moisture-Density Analysis`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation.com/wp-content/uploads/2020/02/Laboratory-compaction-test.pdf"
      }}>{`Laboratory compaction test`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation.com/wp-content/uploads/2020/02/Soil-compaction.pdf"
      }}>{`Soil compaction`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation.com/wp-content/uploads/2020/02/Consolidation-Permeability.docx"
      }}>{`Consolidation + Permeability`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation.com/wp-content/uploads/2020/02/Consolidation-test.pdf"
      }}>{`Consolidation test`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation.com/wp-content/uploads/2020/02/Consolidation-test1.pdf"
      }}>{`Consolidation test1`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation.com/wp-content/uploads/2020/02/FIELD-IDENTIFICATION-AND-IN-SITU-TESTING.pdf"
      }}>{`FIELD IDENTIFICATION AND IN-SITU TESTING`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation.com/wp-content/uploads/2020/02/Field-identification-of-soils.pdf"
      }}>{`Field identification of soils`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation.com/wp-content/uploads/2020/02/SOILS-IDENTIFICATION-Hand-Method.pdf"
      }}>{`SOILS IDENTIFICATION, Hand Method`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation.com/wp-content/uploads/2020/02/foundation-and-foundation-walls.pdf"
      }}>{`foundation and foundation walls`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation.com/wp-content/uploads/2020/02/Foundations-1.pdf"
      }}>{`Foundations`}</a></p>
    <h2>{`Geotechnical Engineering I solution to problems`}</h2>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation.com/wp-content/uploads/2020/02/problem-sheet-1-physical-properties-of-soil.pdf"
      }}>{`problem sheet 1 (physical properties of soil)`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation.com/wp-content/uploads/2020/02/problemsheet-2-physical-propertiesof-soil.pdf"
      }}>{`problemsheet 2 (physical propertiesof soil)`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation.com/wp-content/uploads/2020/02/Atterberg-limits.pdf"
      }}>{`Atterberg limits`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation.com/wp-content/uploads/2020/02/compaction.pdf"
      }}>{`compaction`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation.com/wp-content/uploads/2020/02/consolidation.pdf"
      }}>{`consolidation`}</a></p>
    <h2>{`Geotechnical Engineering II Lectures`}</h2>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation.com/geotechnical-engineering-ii-lectures/"
      }}>{`Geotechnical Engineering II Lectures`}</a></p>
    <h2>{`Geotechnical Engineering II Lab lecture slides`}</h2>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation.com/geotechnical-engineering-lab-ii-lectures/"
      }}>{`Geotechnical Engineering II Lab lecture slides`}</a></p>
    <h2>{`Geotechnical engineering II Lab experiments`}</h2>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation.com/geo-technical-engineering-lab/"
      }}>{`Geotechnical engineering II Lab experiments`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      